/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// const genv = require(`${__dirname}/gatsby-env`);
// console.dir({
//   FROM: "[gatsby-browser] genv imported at gatsby-browser",
//   genv,
// });

// You can delete this file if you're not using it
const ReactDOM = require("react-dom");
async function mdelay(ms = 100) {
  console.log(
    "delaying.............................................................................................................."
  );
  return new Promise((r, rj) => {
    setTimeout(() => {
      r("ok");
    }, ms);
  });
}

const googleAnalyticsLoad = () => {
  // Create the <script> tag for the gtag.js library
  const scriptTag = document.createElement("script");
  scriptTag.async = true;
  scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GTM_ID}`;
  document.head.appendChild(scriptTag);

  // Inline script content
  const inlineScript = document.createElement("script");
  const scriptContent = document.createTextNode(`
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${process.env.GATSBY_GTM_ID}');
  `);
  inlineScript.appendChild(scriptContent);
  document.head.appendChild(inlineScript);
};

exports.replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
    googleAnalyticsLoad();
    // googleAnalyticsAttachEvents();
  };
};

// exports.onRouteUpdate = () => {
//   // googleAnalyticsLoad();
// };

/**
 * ATTEMPT TO TURN IT OFF MAKE SURE WIDGETS OK
 */
// exports.replaceHydrateFunction = () => {
//   return (element, container) => {
//     const root = ReactDOM.createRoot(container);
//     root.render(element);
//     // const ENV_ = process.env.GATSBY_NODE_BUILD_CODE_BY_DOMAIN;
//     // console.dir({
//     //   FROM: "ENV_" + "gatsby-browser",
//     //   ENV_: ENV_,
//     // });
//   };
// };

/**
 *
 */
// const script1 = require(`${__dirname}/templates/template-aca/assets/js/aos`);

// const googleAnalyticsAttachEvents = () => {
//   const elements_emoji = document.querySelectorAll(
//     "a.emojibar-single-emoji-anchor"
//   );
//   elements_emoji.forEach((el, index) => {
//     el.addEventListener("click", function (e) {
//       e.preventDefault(); // Prevent the default anchor action
//       console.log(`hello from el_emoji_${index}`);
//       if (gtag)
//         gtag("event", "click_emoji", {
//           element: `emoji_${index}`,
//           component: "EmojiBar",
//         });
//     });
//   });
// };
